<template>
  <div class="manual">
    <ManualFights :farmers="farmers" :requestFarmers="this.update"></ManualFights>    
  </div>
</template>

<script>

import store from "@/store";
import Vue from 'vue';
import ManualFights from "@/components/ManualFights.vue";

export default {
  components: {
    ManualFights
  },
  data() {
    return {
      user: store.getters.getUser,
      farmers: [],
      unsubFarmers: null,
    }
  },
  async mounted() {
    this.getFullFarmers({uid: this.user.uid}).then((res) => {
      this.farmers = res.data.farmers;
    })
  },
  methods: {
    update() {
      this.getFullFarmers({uid: this.user.uid}).then((res) => {
        this.farmers = res.data.farmers;
      });  
    },
    getFullFarmers: Vue.firebase.functions.getFullFarmers
  }
}

</script>

<style>
ul li {
  text-align: left;
}

</style>
